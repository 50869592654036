import {Container, Nav, Navbar} from 'react-bootstrap';
import React, {useContext, useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import styled, {ThemeContext} from 'styled-components';
import endpoints from '../../config/SystemConstants';
import {INavBar, NavBar} from "../../model/NavbarModel";

const styles = {
    logoStyle: {
        width: 50,
        height: 40,
    },
};

const ExternalNavLink = styled.a`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
`;

const InternalNavLink = styled(NavLink)`
  color: ${(props) => props.theme.navbarTheme.linkColor};
  &:hover {
    color: ${(props) => props.theme.navbarTheme.linkHoverColor};
  }
  &::after {
    background-color: ${(props) => props.theme.accentColor};
  }
  &.navbar__link--active {
    color: ${(props) => props.theme.navbarTheme.linkActiveColor};
  }
`;

const NavBarComponent = () => {
    const theme = useContext(ThemeContext);
    const [data, setData] = useState<NavBar>();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        axios
            .get(endpoints.navbar)
            .then((res: AxiosResponse<INavBar>) => {
                setData(new NavBar(res.data));
            })
    }, []);

    return (
        <Navbar
            fixed="top"
            expand="md"
            bg="dark"
            variant="dark"
            className="navbar-custom"
            expanded={expanded}
        >
            <Container>
                {data?.logo && (
                    <Navbar.Brand href="/">
                        <img
                            src={data?.logo?.source}
                            className="d-inline-block align-top"
                            alt="main logo"
                            style={
                                data?.logo?.height && data?.logo?.width
                                    ? { height: data?.logo?.height, width: data?.logo?.width }
                                    : styles.logoStyle
                            }
                        />
                    </Navbar.Brand>
                )}
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto" />
                    <Nav>
                        {data
                            && data.sections?.map((section, index) => (section?.type === 'link' ? (
                                <ExternalNavLink
                                    key={section.title}
                                    href={section.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => setExpanded(false)}
                                    className="navbar__link"
                                    theme={theme}
                                >
                                    {section.title}
                                </ExternalNavLink>
                            ) : (
                                <InternalNavLink
                                    key={section.title}
                                    onClick={() => setExpanded(false)}
                                    exact={index === 0}
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to={section.href}
                                    theme={theme}
                                >
                                    {section.title}
                                </InternalNavLink>
                            )))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const NavBarWithRouter = withRouter(NavBarComponent);
export default NavBarWithRouter;
