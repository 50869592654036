import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import axios, {AxiosResponse} from 'axios';
import FallbackSpinner from './components/widget/FallbackSpinner';
import NavBarWithRouter from './components/widget/NavBarComponent';
import HomePage from './components/page/HomePage';
import endpoints from './config/SystemConstants';
import {IRoutes, Routes} from "./model/RoutesModel";
import SideBarWidget from "./components/widget/SideBarWidget";

function MainApp() {
    const [data, setData] = useState<Routes>();

    useEffect(() => {
        axios
            .get(endpoints.routes)
            .then((res: AxiosResponse<IRoutes>) => {
                setData(new Routes(res.data));
            })
    }, []);

    return (
        <div className="MainApp">
            <NavBarWithRouter/>
            <main className="main">
                <Switch>
                    <Suspense fallback={<FallbackSpinner/>}>
                        <SideBarWidget/>
                        <Route exact path="/" component={HomePage}/>
                        {data && data.sections.map((route) => {
                            const SectionComponent = React.lazy(() => import('./components/' + route.component));
                            return (
                                <Route
                                    key={route.headerTitle}
                                    path={route.path}
                                    component={() => (
                                        <SectionComponent header={route.headerTitle}/>
                                    )}
                                />
                            );
                        })}
                    </Suspense>
                </Switch>
            </main>
        </div>
    );
}

export default MainApp;
