export const staticPrefix = 'static/profile';
export const apiPrefix = 'api/v1';
const delimiter = '/';

const endpoints = {
    navbar: staticPrefix.concat(delimiter, 'navbar.json'),
    routes: staticPrefix.concat(delimiter, 'routes.json'),
    home: staticPrefix.concat(delimiter, 'home.json'),
    social: staticPrefix.concat(delimiter, 'social.json'),
    about: staticPrefix.concat(delimiter, 'about.json'),
    experiences: staticPrefix.concat(delimiter, 'experiences.json'),
    projects: staticPrefix.concat(delimiter, 'projects.json'),
    report: apiPrefix.concat(delimiter, 'data'),
    auth: 'https://auth.inetfly.ru/realms/inetfly',
};

export default endpoints;

