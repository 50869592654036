export interface INavBar {
    logo: INavBarLogo;
    sections: INavBarSections[];
}

export class NavBar implements INavBar {
    private _logo: NavBarLogo;
    private _sections: NavBarSections[];

    constructor({logo, sections}: INavBar) {
        this._logo = new NavBarLogo(logo);
        this._sections = sections.map((s) => new NavBarSections(s));
    }

    get logo(): NavBarLogo {
        return this._logo;
    }

    set logo(value: NavBarLogo) {
        this._logo = value;
    }

    get sections(): NavBarSections[] {
        return this._sections;
    }

    set sections(value: NavBarSections[]) {
        this._sections = value;
    }
}

export interface INavBarLogo {
    source: string;
    height: number;
    width: number;
}

class NavBarLogo implements INavBarLogo {
    private _height: number;
    private _source: string;
    private _width: number;

    constructor(data: INavBarLogo) {
        Object.assign(this, data)
    }

    get height(): number {
        return this._height;
    }

    set height(value: number ) {
        this._height = value;
    }

    get source(): string  {
        return this._source;
    }

    set source(value: string ) {
        this._source = value;
    }

    get width(): number  {
        return this._width;
    }

    set width(value: number ) {
        this._width = value;
    }
}

export interface INavBarSections {
    title: string;
    href: string;
    type?: string;
}

class NavBarSections implements INavBarSections {
    private _href: string;
    private _title: string;
    private _type: string;

    constructor(data: INavBarSections) {
        Object.assign(this, data)
    }

    get href(): string {
        return this._href;
    }

    set href(value: string) {
        this._href = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string ) {
        this._title = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string ) {
        this._type = value;
    }
}
