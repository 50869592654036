import React, {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import endpoints from '../../config/SystemConstants';
import FallbackSpinner from '../widget/FallbackSpinner';
import {Home, IHome} from '../../model/HomeModel';
import ReactMarkdown from "react-markdown";
import HeaderComponent from "../widget/HeaderComponent";

const styles = {
    nameStyle: {
        fontSize: '5em',
    },
    subTitleStyle: {
        fontSize: '2em',
    },
    inlineChild: {
        display: 'inline-block',
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    pageContainer: {
        textAlign: 'left' as const,
    },
    imgStyle: {
        display: 'flex',
        //margin: 'right',
        height: 200,
    },
};

function HomePage() {
    const [data, setData] = useState<Home>();
    useEffect(() => {
        axios
            .get(endpoints.home)
            .then((res: AxiosResponse<IHome>) => {
                setData(new Home(res.data));
            })
    }, []);

    return data ? (
        <div style={styles.mainContainer}>
            <HeaderComponent title={data?.name}/>
            <h2 style={styles.subTitleStyle}>{data?.subTitle}</h2>
            <div style={styles.pageContainer}>
                <img src={data?.img} alt="logo" style={styles.imgStyle}/>
                <ReactMarkdown children={data?.text}/>
            </div>
        </div>
    ) : <FallbackSpinner/>;
}

export default HomePage;
