import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';

function HeaderComponent(props) {
    const { title } = props;
    return <div className="header">{title}</div>;
}

HeaderComponent.propTypes = {
    title: PropTypes.string.isRequired,
};

export default HeaderComponent;
