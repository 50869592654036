import React from 'react';
import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar} from 'react-pro-sidebar';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CarpenterIcon from '@mui/icons-material/Carpenter';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import {Link} from "react-router-dom";
import SocialWidget from "./SocialWidget";

function SideBarWidget() {
    const {collapseSidebar} = useProSidebar();
    return (
        <Sidebar style={{textAlign: "left", height: "100vh"}}>
            <Menu>
                <MenuItem icon={<MenuOutlinedIcon/>} style={{textAlign: "center"}} onClick={() => {
                    collapseSidebar();
                }}>
                    Menu
                </MenuItem>
                <MenuItem icon={<HomeIcon/>} component={<Link to="/"/>}> Главная </MenuItem>
                <MenuItem icon={<CarpenterIcon/>} component={<Link to="/tools"/>}> Tools </MenuItem>
                <MenuItem icon={<ArticleIcon/>} component={<Link to="/about"/>}> About </MenuItem>
                <SubMenu icon={<AssessmentIcon/>} label="Reports">
                    <MenuItem icon={<TimelineRoundedIcon/>} component={<Link to="report"/>}> Report </MenuItem>
                    <MenuItem icon={<BubbleChartRoundedIcon/>} component={<Link to="charts"/>}> Charts </MenuItem>
                </SubMenu>
            </Menu>
            <SocialWidget/>
        </Sidebar>
    );
}

export default SideBarWidget;