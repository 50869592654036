import React, {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {SocialIcon} from 'react-social-icons';
import {ISocial, Social} from '../../model/SocialModel'
import endpoints from '../../config/SystemConstants';


const styles = {
    iconStyle: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
    },
};

function SocialWidget() {
    const [data, setData] = useState<Social>();

    useEffect(() => {
        axios
            .get(endpoints.social)
            .then((res: AxiosResponse<ISocial>) => {
                setData(new Social(res.data));
            })
    }, []);
    
    return (
        <div className="social">
            {data ? data.social.map((social) => (
                <SocialIcon
                    key={social.network}
                    style={styles.iconStyle}
                    url={social.href}
                    network={social.network}
                    target="_blank"
                    rel="noopener"
                />
            )) : null}
        </div>
    );
}

export default SocialWidget;
